import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom'; 

gsap.registerPlugin(ScrollTrigger);

const FooterContainer = styled.div`
  color: white;
  text-align: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px; 
  height: auto; 
  margin-top: 2em;

  @media (max-width: 500px) {
    width: 30%;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -2em;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
`;

const IconText = styled.span`
  font-size: 14px;
  width: auto;
  color: #B98844;

  /* Adjusted the CSS to make the span clickable */
  cursor: pointer;

  a {
    text-decoration: none;
    color: #B98844;
    cursor: pointer;
  }
`;

const Footer = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const wrapper = contentRef.current;

    gsap.fromTo(
      wrapper, 
      { autoAlpha: 0 ,y:100 },
      { 
        autoAlpha: 1,
        duration: 1,
        y:0,
        scrollTrigger: {
          trigger: wrapper,
          start: 'top 100%',
        }
      }
    );
  }, []);

  return (
    <FooterContainer>
      <ContentWrapper ref={contentRef}>
        <IconsWrapper>
          <a href="https://www.linkedin.com/company/clubaffiliates/?viewAsMember=true"><Icon src="/img/in.png" alt="Icon 1" /></a>
          <Icon src="/img/facebook.png" alt="Icon 2" />
          <Icon src="/img/x.png" alt="Icon 3" />
          <Icon src="/img/insta.png" alt="Icon 4" />
        </IconsWrapper>
        {/* Wrapped the entire text with the Link component */}
        <Link to="/terms" style={{ textDecoration: 'none' }}>
          <IconText>
            Copyright © 2024 Club Affiliates &nbsp; | &nbsp; 
            <span>Terms & Conditions</span>
          </IconText>
        </Link>
        <Logo src="/img/Logo.png" alt="Logo" />
      </ContentWrapper>
    </FooterContainer>
  );
};

export default Footer;
