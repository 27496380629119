import React, { useState, useEffect ,useRef} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ImageTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 900px;
  flex-direction: column;
  position: relative;
  scroll-margin-top: 160px;

  @media (max-width: 1000px) {
    height: auto;
  }
`;

const SecImage = styled.img`
  width: 30%;
  z-index: 3;
  max-width: 620px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1350px) {
    width: 35%;
  }
`;


const Title = styled.h1`
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
  font-style: italic;
  font-weight: bold; 
  background: linear-gradient(to right, #d9ae65, #9b6525);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 430px) {
    font-size: 20px;
  }
`;

const TextBlock = styled.div`
  position: absolute;
  width: auto;
  z-index: 3;
  
  @media (min-width: 1661px) {
    &.first {
      top: 0%;
      left: 25%;
    }

    &.sec {
      top:30%;
      left:10%;
    }

    &.third {
      top:60%;
      left:13%;
    }

    &.four {
     top:70%;
     left:50%;
    }

    &.five {
      top:44%;
      left:70%;
    }

    
    &.six {
     top:5%;
     left:60%;
    }
  }

  @media (max-width: 1660px) {
    &.first {
      top: 12%;
      left: 20%;
    }

    &.sec {
      top: 40%;
      left: 8%; /* Change the left value */
    }

    &.third {
      top:70%;
      left:15%;
    }

    &.four {
     top:73%;
     left:50%;
    }

    &.five {
      top:44%;
      left:70%;
    }

    
    &.six {
     top:15%;
     left:60%;
    }
  }

  @media (max-width: 1350px) {
    width: 350px;

    &.sec {
      top: 40%;
      left: 4%;
    }
  }

  @media (max-width: 1180px) {

    &.sec {
      width: 300px;
    }

    &.third {
      left: 10%;
    }

    &.four {
      left: 53%;
    }

    &.five {
      left: 70%;
      width: 300px;
    }
  }
`;

const Text = styled.p`
  color: white;
  font-size: 18px;

  @media (min-width: 1350px) {
    white-space:pre-line;
  }

  @media (max-width: 430px) {
   font-size: 16px;
  }
`;

const RoundedButton = styled.button`
  background-color: #B98844;
  color: black;
  width: 150px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
  border: 1px solid #B98844;
  margin-left:30%;

  &:hover {
    background-color: transparent; 
    color: white;
  }
`;

const Whyg = styled.img`
  width: auto;
  position: absolute;
  z-index: 1;
  top: 70%;
  right: -50em;
  transform: translateY(-50%) rotate(-15deg); 

  @media (max-width: 800px) {
    top: 50%;
    width: 900px;
    transform: translateY(-50%) rotate(-10deg); 
  }
`;

const WhygR = styled.img`
  width: 90%;
  position: absolute;
  z-index: 1;
  top: 1200px;
  left: -60em;
  transform: translateY(-50%) rotate(7deg); 

  @media (max-width: 800px) {
    top: 50%;
    width: 900px;
    transform: translateY(-50%) rotate(-10deg); 
  }
`;

const AnotherImage = styled.img`
  width: 20%; 
  display: flex;
  margin-top: -16%;
  align-items: center;
  z-index:444;
`;

// -----------mobile-----------

const Titleimg = styled.img`
  width: 70%;
  max-width: 500px;
  height: auto;
  margin-bottom: 80px;

  @media (max-width: 430px) {
    width: 60%;
    margin-bottom: 38px;
  }
`;

const BrandItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  text-align: left;
  margin-bottom: 30px;


  @media (max-width: 430px) {
    max-width: none;
    width: 360px;
    margin: 0px;
    margin-bottom: 70px;
  }

  @media (max-width: 400px) {
    max-width: none;
    width: 330px;
    margin: 0px;
    margin-bottom: 70px;
  }
`;

const BrandContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  z-index: 2;
 /* background-color: red; */

`;

const Why = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const titleRef = useRef(null);
  const casinoRef = useRef(null);
  const block1Ref = useRef(null);
  const block2Ref = useRef(null);
  const block3Ref = useRef(null);
  const block4Ref = useRef(null);
  const block5Ref = useRef(null);
  const block6Ref = useRef(null);
  
  useEffect(() => {
    const title = titleRef.current;
    const casino = casinoRef.current;
    const block1 = block1Ref.current;
    const block2 = block2Ref.current;
    const block3 = block3Ref.current;
    const block4 = block4Ref.current;
    const block5 = block5Ref.current;
    const block6 = block6Ref.current;

    gsap.fromTo( title,
        { autoAlpha: 0 }, // start state: invisible and translateY 100px down
        { 
          autoAlpha: 1, // end state: fully visible
          duration: 2, // duration of the animation
          scrollTrigger: {
            trigger: title, // element that triggers the animation
            start: 'top 100%', // start the animation when the top of the trigger element is 80% in view
          }
        }
    );

    gsap.fromTo( casino,
        { autoAlpha: 0 }, // start state: invisible and translateY 100px down
        { 
          autoAlpha: 1, // end state: fully visible
          duration: 3, // duration of the animation
          scrollTrigger: {
            trigger: casino, // element that triggers the animation
            start: 'top 100%', // start the animation when the top of the trigger element is 80% in view
          }
        }
    );

    gsap.fromTo( block1,
        { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
        { 
          autoAlpha: 1, // end state: fully visible
          x:0,
          duration: 1.2, // duration of the animation
          scrollTrigger: {
            trigger: block1, // element that triggers the animation
            start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
          }
        }
    );

    gsap.fromTo(block6,
      { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
      { 
        autoAlpha: 1, // end state: fully visible
        x:0,
        delay:0.3,
        duration: 1.2, // duration of the animation
        scrollTrigger: {
          trigger: block6, // element that triggers the animation
          start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
        }
      }
    );

    gsap.fromTo(block2,
      { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
      { 
        autoAlpha: 1, // end state: fully visible
        x:0,
        delay:0.5,
        duration: 1.2, // duration of the animation
        scrollTrigger: {
          trigger: block2, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        }
      }
    );

    gsap.fromTo(block3,
      { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
      { 
        autoAlpha: 1, // end state: fully visible
        x:0,
        delay:0.8,
        duration: 1.2, // duration of the animation
        scrollTrigger: {
          trigger: block3, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        }
      }
    );

    gsap.fromTo(block4,
      { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
      { 
        autoAlpha: 1, // end state: fully visible
        x:0,
        delay:0.8,
        duration: 1.2, // duration of the animation
        scrollTrigger: {
          trigger: block4, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        }
      }
    );

    gsap.fromTo(block5,
      { autoAlpha: 0 ,x:-90}, // start state: invisible and translateY 100px down
      { 
        autoAlpha: 1, // end state: fully visible
        x:0,
        delay:1,
        duration: 1.2, // duration of the animation
        scrollTrigger: {
          trigger: block5, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        }
      }
    );

  }, []);


  return (
    <ImageTopContainer id="about">
      {windowWidth > 1000 ? (
        <>
          <SecImage src="/img/us.png" alt="Title" ref={titleRef}/>
          <AnotherImage src="/img/club2.png" alt="Another Image" ref={casinoRef}/>
          <Whyg src="/img/whyG.png" alt="Mobile Image" />
          <WhygR src="/img/bgwhy.png" alt="Mobile Image" />
          <TextBlock ref={block1Ref} className="first">
            <Title>Unlock commissions up to 50%</Title>
            <Text>Fuel your success with Club Affiliates! Score up to a{"\n"}
             thrilling 50% commission on Net Gaming Revenue – turning{"\n"} 
             every player into a direct path to exciting and lucrative rewards!</Text>
          </TextBlock>

          <TextBlock ref={block2Ref} className="sec">
            <Title>No Negative Carry Over</Title>
            <Text>With a new month comes a fresh start.{"\n"}
            Leave last month’s wins and losses behind,{"\n"}
            and let’s make the most of what’s ahead!</Text>
          </TextBlock>

          <TextBlock ref={block3Ref} className="third" >
            <Title>Integration Made Easy</Title>
            <Text>We provide server-side pixels, also known as{"\n"}
             postbacks or s2s, facilitating direct communication{"\n"}
            between our affiliate program system and your{"\n"}
             affiliate system’s serve</Text>
          </TextBlock>

          <TextBlock ref={block4Ref} className="four">
            <Title>Instant Support</Title>
            <Text>Having a dedicated account manager is {"\n"}
              like having a buddy in the biz. No question {"\n"}
              is too small – got something on your mind? {"\n"}
              Hit us up. Whether it’s email, message or Skype,{"\n"}
               we’re just a shout away with the answers you need.</Text>
          </TextBlock>

          <TextBlock ref={block5Ref} className="five">
            <Title>The Club Is It's Players</Title>
            <Text>Bringing a wealth of experience to the table, {"\n"}
            we’re all about putting players front and center. {"\n"}
            We analyse your performance after each month{"\n"}
            and give you feedback on how we can help you{"\n"}
            achieve more.</Text>
          </TextBlock>

          <TextBlock ref={block6Ref} className="six">
            <Title>Quick Payments</Title>
            <Text>We understand the importance of timely{"\n"} 
            earnings, and we’ve got your back. {"\n"}
            We ensure swift payments – expect your{"\n"} 
            commissions to hit your account by {"\n"}
            the 10th of every month.</Text>
          </TextBlock>
        </>
      ) : (
        <>
          <Titleimg src="/img/us.png" alt="Title" />
          <Whyg src="/img/whyG.png" alt="Mobile Image" />
          <BrandContainer>
          <BrandItem>
            <Title>Unlock commissions up to 50%</Title>
            <Text>Fuel your success with Club Affiliates! Score up to a
             thrilling 50% commission on Net Gaming Revenue – turning
             every player into a direct path to exciting and lucrative rewards!</Text>
          </BrandItem>

        <BrandItem style={{top:'40%',left:'10%'}}>
              <Title>No Negative Carry Over</Title>
              <Text>With a new month comes a fresh start.
              Leave last month’s wins and losses behind,
              and let’s make the most of what’s ahead!</Text>
        </BrandItem>

            <BrandItem style={{top:'70%',left:'15%'}}>
              <Title>Integration Made Easy</Title>
              <Text>We provide server-side pixels, also known as
              postbacks or s2s, facilitating direct communication
              between our affiliate program system and your
              affiliate system’s serve</Text>
          </BrandItem>

          <BrandItem style={{top:'73%',left:'50%'}}>
            <Title>Instant Support</Title>
            <Text>Having a dedicated account manager is 
              like having a buddy in the biz. No question 
              is too small – got something on your mind? 
              Hit us up. Whether it’s email, message or Skype,
               we’re just a shout away with the answers you need.</Text>
          </BrandItem>

          <BrandItem style={{top:'44%',left:'70%'}}>
            <Title>The Club Is It's Players</Title>
            <Text>Bringing a wealth of experience to the table, 
            we’re all about putting players front and center.
            We analyse your performance after each month
            and give you feedback on how we can help you
            achieve more.</Text>
          </BrandItem>

          <BrandItem style={{top:'15%',left:'60%'}}>
            <Title>Quick Payments</Title>
            <Text>We understand the importance of timely
            earnings, and we’ve got your back.
            We ensure swift payments – expect your
            commissions to hit your account by
            the 10th of every month.</Text>
          </BrandItem>
          </BrandContainer>
        </>
      )}
      {/* <RoundedButton>JOIN US</RoundedButton> */}
    </ImageTopContainer>
  );
};

export default Why;
