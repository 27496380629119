import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Register from './Register';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const HomeSec = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  margin-top: 6em;

  @media (max-width: 1300px) {
    height: 700px;
  }

  @media (max-width: 900px) {
    height: 600px;
  }

  @media (max-width: 800px) {
    height: 550px;
  }

  @media (max-width: 600px) {
    height: 600px;
  }

  @media (max-width: 430px) {
    height: 500px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }
`;

const RotatedBackground = styled.img`
  width: 70%;
  top: 20em;
  right: 0;
  position: absolute;
  z-index: 0;

  @media (max-width: 1400px) {
    width: 100%;
    top: 7em;
    left: 10em;
  }

  @media (max-width: 900px) {
    width: 100%;
    left: 7em;
  }

  @media (max-width: 800px) {
    top: 10em;
  }

  @media (max-width: 600px) {
    top: 12em;
    left: 4em;
  }

  @media (max-width: 500px) {
    left: 2em;
  }
`;

const RotatedBackgroundB = styled.img`
  width: 65%;
  transform: rotate(-70deg);
  z-index: 1;
  top: -5em;
  left: 0;
  position: absolute;
  z-index: 1;

  @media (max-width: 1400px) {
    width: 80%;
    top: -10em;
    left: -5em;
  }

  @media (max-width: 1200px) {
    top: -7em;
  }

  @media (max-width: 900px) {
    width: 85%;
  }

  @media (max-width: 600px) {
    top: -3em;
    right: -2em;
  }

  @media (max-width: 500px) {
    width: 100%;
    top: -4em;
    right: -2em;
  }

  @media (max-width: 430px) {
    width: 650px;
    height: 600px;
    top: -8em;
    left: -12em;
  }
`;

const CasinoImg = styled.img`
  width: 600px;
  top: 120px;
  margin-right: 10%;
  margin-left: 50%;
  z-index: 1;
  position: absolute;

  @media (max-width: 1714px) {
    margin-right: 10%;
  margin-left: 60%;
  }

  @media (max-width: 1385px) {
    margin-right: 5%;
    margin-left: 60%;
  }

  @media (max-width: 1203px) {
    width: 500px;
    top: 125px;
  }

  @media (max-width: 900px) {
    margin-right: 5%;
  margin-left: 50%;
  }

  
  @media (max-width: 658px) {
    margin-right: 3%;
  margin-left: 50%;
  }

  @media (max-width: 540px) {
    width: 400px;
    top: 100px;
    margin-right: 3%;
  margin-left: 50%;
  }
`;

const Subtitle = styled.h2`
  display: flex;
  justify-content: center;
  white-space: pre-line;
  text-align: center;
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 5px;
  padding-bottom: 25px;
  z-index: 1;

  @media (max-width: 658px) {
    font-size: 20px;
  }

  @media (max-width: 540px) {
    font-size: 16px;
  }

  @media (max-width: 430px) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 500px;
  z-index: 2;
  position: absolute;

  @media (max-width: 1385px) {
    top: 470px;
  }

  @media (max-width: 1203px) {
    top: 430px;
  }

  @media (max-width: 1022px) {
    top: 390px;
  }

  @media (max-width: 658px) {
    top: 360px;
  }

  @media (max-width: 540px) {
    top: 290px;
  }
`;

const Button = styled.button`
  background-color: #B98844;
  color: black;
  width: 150px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
  border: 1px solid #B98844;
  transition: background-color 0.3s; 

  &:hover {
    background-color: transparent; 
    color: white;
  }

  @media (max-width: 430px) {
    width: 109px;
    height: 28px;
    font-size: 14px;
    padding: 0;
    border-radius: 5px;
  }
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 7em;
  font-weight: 100;
  letter-spacing: 5px;
  z-index: 1000;
  font-family: 'Scotch';
  white-space: pre-line;
  font-style: italic; 
  position: absolute;
  top: 230px;

  
  @media (max-width: 1385px) {
    font-size: 6em;
  }

  @media (max-width: 1203px) {
    font-size: 5em;
  }

  @media (max-width: 1022px) {
    font-size: 4em;
  }
  
  @media (max-width: 900px) {
    width: 500px;
    top: 140px;
    margin-right: 120px;
  }
  
  @media (max-width: 658px) {
    top: 160px;
    margin-right: 20px;
    font-size: 50px;
  }

  @media (max-width: 540px) {
    font-size: 40px;
    top: 130px;
    width: 400px;
  }

  @media (max-width: 430px) {
    font-size: 35px;
    width: 300px;
    top: 150px;
    margin-right:50px;
  }
`;

const Home = () => {
  const [showRegister, setShowRegister] = useState(false); 

  const titleRef = useRef(null);
  const casinoRef = useRef(null);
  const contentRef = useRef(null);
  
  useEffect(() => {
    const title = titleRef.current;
    const casino = casinoRef.current;
    const content = contentRef.current;

    gsap.fromTo(
    title, 
    { autoAlpha: 0 }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 2, // duration of the animation
      delay: 0.3,
      scrollTrigger: {
        trigger: title, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  gsap.fromTo(
    casino, 
    { autoAlpha: 0 }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 2, // duration of the animation
      delay: 0.3,
      scrollTrigger: {
        trigger: casino, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );


  gsap.fromTo(
    content, 
    { autoAlpha: 0 }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 2, // duration of the animation
      delay: 0.5,
      scrollTrigger: {
        trigger: content, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  }, []);

  const handleRegisterClose = () => {
    setShowRegister(false); // Reset showRegister state to false when closing the register
  };

  return (
    <HomeSec id="home">
      <Container>
        <Title ref={titleRef}>Join the club{"\n"} Boost Your Earnings</Title>
        <RotatedBackground src="/img/BG-H.png" alt="img-h" />
        <CasinoImg ref={casinoRef} src="/img/img1.png" alt="img-h" />
        <RotatedBackgroundB src="/img/BG-H2.png" alt="img-h" />

        <Content ref={contentRef}>
          <Subtitle>WE HONOUR OUR PARTNERS AND{"\n"}CELEBRATE TOGETHER</Subtitle>
           <Link to="/#register"><Button onClick={() => setShowRegister(true)}>JOIN US</Button></Link>
        </Content>
      </Container>
      {showRegister && <Register onClose={handleRegisterClose} />} 
    </HomeSec>
  );
};

export default Home;
