import React, { useState , useEffect ,useRef} from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import Login from './Login'; 
import Register from './Register';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const NavbarContainer = styled.nav`
  background: #000000b2;
  color: #fff;
  padding: 20px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; 

  @media (max-width: 991px) {
    padding: 20px 0px;
  }
`;

const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: 991px) {
    padding: 0px 20px;
  }
`;

const Logo = styled.img`
  width: 160px;
  cursor: pointer; 

  @media (max-width: 991px) {
    width: 160px;
  }

  @media (max-width: 600px) {
    width: 130px;
  }

  @media (max-width: 530px) {
    padding: 0;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
`;

const Links = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  @media (max-width: 991px) {
    position: fixed;
    top: 6em; /* Adjust this value based on your navbar height */
    left: 0;
    right: 0;
    font-size: 21px;
    background-color: #000000cd;
    width: 100vw;
    margin: 0 auto;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  @media (max-width: 700px) {
    top: 5em; 
  }
`;

const NavbarLink = styled(HashLink)`
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  margin: 0 10px;
  /* background: red; */

  &:hover {
    color: #BF8F4A;
  }

  &.active {
    color: #BF8F4A;
    text-decoration: underline;
  }

  @media (max-width: 991px) {
    margin: 10px auto; /* Center the buttons */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: red; */

  @media (max-width: 991px) {
    margin-right: 80px;
  }

  @media (max-width: 600px) {
    margin: 0 auto;
    flex-direction: column;
    margin-top: 8px;
  }
`;

const NavbarButton = styled.button`
  text-decoration: none;
  color: black;
  font-size: 1.2em;
  background-color: #BF8F4A;
  border: none;
  border: 1px solid #BF8F4A;
  padding: 7px;
  cursor: pointer;
  margin: 0 6px;
  border-radius: 11px;
  transition: background-color 0.3s; 

  &:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [showLogin, setShowLogin] = useState(false); 
  const [showRegister, setShowRegister] = useState(false); 

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (id) => {
    setActiveLink(id);
    if (id === 'login') {
      setShowLogin(true);
      setShowRegister(false); // Hide the Register component if the Login link is clicked
    } else if (id === 'register') {
      setShowLogin(false); // Hide the Login component if the Register link is clicked
      setShowRegister(true);
    } else {
      setShowLogin(false);
      setShowRegister(false);
    }
  };

  const handleLoginClose = () => {
    setShowLogin(false);
    setShowRegister(false); // Reset showRegister state to false when closing the login
  };

  const handleRegisterClose = () => {
    setShowRegister(false); // Reset showRegister state to false when closing the register
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false); // Close the menu when scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const linksRef = useRef(null);
  
  useEffect(() => {
    const links = linksRef.current;

    gsap.fromTo(
    links,
    { autoAlpha: 0, y: -90 }, // start state: invisible and translateY 100px down
    { 
      autoAlpha: 1, // end state: fully visible
      y: 0, // translateY back to 0
      duration: 1, // duration of the animation
      scrollTrigger: {
        trigger: links, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );
  

  }, []);

  return (
    <>
      <NavbarContainer id="nav">
        <NavbarWrapper  ref={linksRef}>
          <HashLink to="/#home"  className={activeLink === 'home' ? 'active' : ''} onClick={() => handleLinkClick('home')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start'})}><Logo src="/img/Logo.png" alt="Logo" /></HashLink>

          <Links isOpen={isOpen}>
            <NavbarLink to="/#about" className={activeLink === 'about' ? 'active' : ''} onClick={() => handleLinkClick('about')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>WHY US</NavbarLink>
            <NavbarLink to="/#brands" className={activeLink === 'brands' ? 'active' : ''} onClick={() => handleLinkClick('brands')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>BRANDS</NavbarLink>
            <NavbarLink to="/#testimonials" className={activeLink === 'testimonials' ? 'active' : ''} onClick={() => handleLinkClick('testimonials')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>TESTIMONIALS</NavbarLink>
            <NavbarLink to="/#faq" className={activeLink === 'faq' ? 'active' : ''} onClick={() => handleLinkClick('faq')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>FAQ</NavbarLink>
            <NavbarLink to="/#contact" className={activeLink === 'contact' ? 'active' : ''} onClick={() => handleLinkClick('contact')} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>CONTACT</NavbarLink>
       
            {(isOpen && window.innerWidth <= 600) && (
                <ButtonContainer>
                  <NavbarButton className={activeLink === 'register' ? 'active' : ''} onClick={() => handleLinkClick('register')} to="/#register">REGISTER</NavbarButton>
                  <NavbarButton className={activeLink === 'login' ? 'active' : ''} onClick={() => handleLinkClick('login')} to="/#login">LOGIN</NavbarButton>
                </ButtonContainer>
              )}
          </Links>


          {(window.innerWidth > 600) && (
          <ButtonContainer>
            <NavbarButton className={activeLink === 'register' ? 'active' : ''} onClick={() => handleLinkClick('register')} to="/#register">REGISTER</NavbarButton>
            <NavbarButton className={activeLink === 'login' ? 'active' : ''} onClick={() => handleLinkClick('login')} to="/#login">LOGIN</NavbarButton>
          </ButtonContainer>
          )}

          <ToggleButton onClick={toggleNavbar}>
            {isOpen ? <img style={{ width: '30px', height: 'auto' }} src="/img/xmenu.png" alt="close" /> : <img style={{ width: '30px', height: 'auto' }} src="/img/menu.svg" alt="menu" />}
          </ToggleButton>
        </NavbarWrapper>
      </NavbarContainer>
      {showLogin ? <Login onClose={handleLoginClose} /> : ''} 
      {showRegister ? <Register onClose={handleRegisterClose} /> : ''} 
    </>
  );
};

export default Navbar;
