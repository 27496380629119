import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const CollapsibleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  text-align: center;
  color: white;
  margin-top: 70px;
  scroll-margin-top: 160px;

  @media (max-width: 430px) {
    height: 850px;
  }
`;

const Titlecon = styled.img`
  width: 70%;
  max-width: 500px;
  height: auto;
  margin-bottom: 60px;

  @media (max-width: 430px) {
    width: 60%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`;

const Acordioon = styled.div`
  width: 70%;
  
  @media (max-width: 600px) {
    width: 90%;
  }

  @media (max-width: 430px) {
    width: 90%;
  }
`;

const Item = styled.div`
  border: 1px solid #adadad;
  width: 100%;
  border-radius:15px;
  margin-bottom: 25px;
  padding:20px 20px;

  @media (max-width: 430px) {
    width: 100%;
    padding:10px 20px;
  }
`;

const Title = styled.div`
  color: ${(props) => (props.isOpen ? '#B98844' : 'white')};
  display: flex;
  justify-content: left;
  text-align: left;
  cursor: pointer;
  margin: 10px 0;

  @media (max-width: 568px) {
    font-size:11px;
  }

  @media (max-width: 431px) {
    font-size:10px;
  }

  @media (max-width: 393px) {
    font-size:9px;
  }
`;

const Content = styled.div`
  color: #B98844;
  max-height: 0;
  overflow: hidden;
  transition: all 0.01s cubic-bezier(0, 1, 0, 1);
    text-align: left;
    font-size: 1em;
    margin-left: 80px;
    width: 65%;
    font-size:16px;

  &.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.01s cubic-bezier(1, 0, 1, 0);
  }

  @media (max-width: 430px) {
    font-size:14px;
    width: 80%;
    margin-left: 4.5em;
  }

  @media (max-width: 393px) {
    font-size:14px;
    width: 80%;
    margin-left: 4.5em;
  }
`;

const CollapsibleComponent = () => {
  const [selected, setSelected] = useState(null);

  const data = [
    {
      question: "What does 'affiliate Program' mean?",
      answer:
        "An affiliate program is an arrangement between ourselves and the affiliates. We pay the affiliates to send traffic to our ever growing brands.",
    },
    {
      question: "How can I sign up as an affiliate?",
      answer:
        'You can register to our affiliate platform by clicking ‘Register’ at the top right-hand side of our homepage and filling in all necessary details. Once registered, one of our trusty Affiliate Managers will be in contact with you.',
    },
    {
      question: "How much will it cost me to join the program?",
      answer:
        "Absolutely free of charge! Once registered you will discuss a deal for your traffic with your appointed Affiliate Manager.",
    },
    {
      question: "What do the terms CPA and RevShare mean?",
      answer:
        'CPA (cost per acquisition) means we pay an agreed amount per player’s first FTD on our brand. RevShare (revenue share) means we agree to pay you a percentage of the revenue of all of your traffic each month.'  
    },
    {
      question: "How can I contact you?",
      answer:
        "Via our contact form or at partners@clubaffiliates.com",
    },
    {
      question: "What deals do you have?",
      answer:
        'Commissions are determined depending on the value and quantity of players you attract.',
    },
  ];

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  const titleRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;

    gsap.fromTo(
    title, 
    { autoAlpha: 0 ,y:100 }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, // duration of the animation
      y:0,
      scrollTrigger: {
        trigger: title, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  gsap.fromTo(
    '.faq',
    { autoAlpha: 0  }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, 
      stagger: {
      each: 0.4, // Adjust this value to change the delay between each testimonial block
    },
      scrollTrigger: {
        trigger: '.faq', // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  }, []);

  return (
    <CollapsibleContainer id="faq">
      <Titlecon src="/img/faq.png" alt="Brand Title" ref={titleRef}/>
      <Wrapper>
        <Acordioon>
          {data.map((item, index) => (
            <Item key={index} onClick={() => toggle(index)}  className="faq">
              <Title isOpen={selected === index}>

              <span>

                {selected === index ? (
                  <img style={{ width: window.innerWidth > 430 ? '30px' : '20px', marginTop: '5px' }} src="/img/aup.svg" alt="up arrow" />
                ) : (
                    <img style={{ width: window.innerWidth < 430 ? '20px' : '30px', marginTop: '5px' }} src="/img/adown.svg" alt="down arrow" />
                )}
              </span>


                <h2 style={{marginLeft:'45px',fontSize:'18px'}}>{item.question}</h2>
              
              </Title>
              <Content className={selected === index ? 'show' : ''}>
                {item.answer}
              </Content>
            </Item>
          ))}
        </Acordioon>
      </Wrapper>
    </CollapsibleContainer>
  );
};

export default CollapsibleComponent;
