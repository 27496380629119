import React, { useEffect, useState,useRef } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 5%;
  scroll-margin-top: 120px;
`;

const ImageFormContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const BrandTitle = styled.img`
  width: 50%;
  height: auto;
  margin-bottom: 70px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 45%;
  height: auto;

  @media (max-width: 1100px) {
    width: 50%;
    margin-top: 3em;
  }

  @media (max-width: 700px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  width: 20%;
  margin-right: 200px;

  @media (max-width: 1300px) {
    align-items: center;
    margin-right: 0;
    width: 40%;
  }

  @media (max-width: 1000px) {
    width: 70%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: white;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

const Input = styled.input`
  margin-bottom: 50px;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 2px solid #B98844;
  outline: none;
  font-size: 20px;
  line-height: 35px;

  &::placeholder {
    color: white;
  }

  @media (max-width: 1000px) {
    padding: 0px;
    margin-bottom: 30px;
    width: 100%;
    font-size: 16px;
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  font-size: 20px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 2px solid #B98844;
  outline: none;
  line-height: 35px;

  &::placeholder {
    color: white;
  }

  @media (max-width: 1000px) {
    padding: 0px;
    width: 100%;
    font-size: 16px;
  }
`;

const Button = styled.button`
  background-color: #B98844;
  border: 1px solid #B98844;
  color: black;
  padding: 10px 20px;
  width: 200px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 430px) {
    padding: 5px 10px;
  }
`;

const FormComponent = () => {

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    const email = form.current.email.value; // Get the email input value
    console.log('Email:', email);
    if (!emailRegex.test(email)) {
      alert('Invalid email address');
      return;
    }
  
    emailjs
      .sendForm('service_g8laz8v', 'template_x4s5jzn', form.current, 'id3A3dgSk2LLq8Qud')
      .then((result) => {
        console.log(result.text);
        setFormSubmitted(true);
        form.current.reset();
      })
      .catch((error) => {
        console.error('EmailJS Error:', error);
      });

      console.log('Email sent successfully!');
  };

  const titleRef = useRef(null);
  const clubRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;
    const club = clubRef.current;
    const form = formRef.current;

    gsap.fromTo(
      title,
      { autoAlpha: 0, y: 100 }, // start state: invisible
      {
        autoAlpha: 1, // end state: fully visible
        duration: 1, // duration of the animation
        y: 0,
        scrollTrigger: {
          trigger: title, // element that triggers the animation
          start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
        },
      }
    );

    gsap.fromTo(
      club,
      { autoAlpha: 0, y: 100 }, // start state: invisible
      {
        autoAlpha: 1, // end state: fully visible
        duration: 1, // duration of the animation
        y: 0,
        delay: 0.5,
        scrollTrigger: {
          trigger: club, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        },
      }
    );

    gsap.fromTo(
      form,
      { autoAlpha: 0, y: 100 }, // start state: invisible
      {
        autoAlpha: 1, // end state: fully visible
        duration: 1, // duration of the animation
        y: 0,
        delay: 0.7,
        scrollTrigger: {
          trigger: form, // element that triggers the animation
          start: 'top 90%', // start the animation when the top of the trigger element is 80% in view
        },
      }
    );
  }, [titleRef, clubRef, formRef]);

  return (
    <Container id="contact">
      <BrandTitle src="/img/join.png" alt="Brand Title" ref={titleRef} />
      <ImageFormContainer>
        <Image src="/img/club.png" alt="Right Image" ref={clubRef} />
        <FormContainer>
          <Form ref={form} onSubmit={sendEmail}>
            <Input
              type="text"
              name="name"
              placeholder="FULL NAME*"
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder="PHONE"
            />
            <Input
              type="email"
              name="email"
              placeholder="E-MAIL*"
              required
            />
            <TextArea
              name="message"
              placeholder="MESSAGE*"
              rows="1"
              required
            />
            <Button type="submit">SEND MESSAGE</Button>
          </Form>
        </FormContainer>
      </ImageFormContainer>
    </Container>
  );
};

export default FormComponent;
