import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  min-height: 100vh;
  margin-bottom: 100px;
`;

const Title = styled.h1`
  color: #B98844;
  align-items: center;
  text-align:center;
  font-size: 5em;
  font-weight: 100;
  letter-spacing: 5px;
  font-family: 'Scotch';
  white-space: pre-line;
  font-style: italic; 
  color: #B98844;
  margin-top: 250px; 
  margin-bottom:50px;

  @media (max-width:650px){
    font-size: 5em;
  }

  @media (max-width:464px){
    font-size: 4em;
  }

  @media (max-width:380px){
    font-size: 3em;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 55%;
  margin-top: 50px;

  @media (max-width:1074px){
    width: 600px;
  }

  @media (max-width:650px){
    width: 90%;
  }
`;

const SubTitle = styled.h2`
  color: #B98844;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left; /* Align subtitle to the left */
`;

const Text = styled.p`
  color: white;
  font-size: 18px;
  white-space: pre-line;
  text-align: left; 
  margin-bottom: 20px;
`;

const Terms = () => {

  const titleRef = useRef(null);

  useEffect(() => {
    const title = titleRef.current;


    gsap.fromTo(
    title, 
    { autoAlpha: 0  }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, 
      scrollTrigger: {
        trigger: title, // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  gsap.fromTo(
    '.term',
    { autoAlpha: 0  }, // start state: invisible
    { 
      autoAlpha: 1, // end state: fully visible
      duration: 1, 
      scrollTrigger: {
        trigger: '.term', // element that triggers the animation
        start: 'top 80%', // start the animation when the top of the trigger element is 80% in view
      }
    }
  );

  window.scrollTo({ top: 0, behavior: 'smooth' });

  }, []);

  return (
    <TermsContainer id="terms">
      <Title ref={titleRef}>Terms & Conditions</Title>
      
      <TextBox className="term">
        <SubTitle>INTRODUCTION</SubTitle>
        <Text>
          By accepting the Terms and Conditions set forth below (the "Agreement"), you (the "Affiliate" or "you") agree to form a collaborative partnership with Vital Edge Ltd. ("Vital Edge" or "us"). This partnership involves the integration and promotion of Vital Edge's services and products on your platform(s) using trackable promotional materials provided by us. In return, you will receive compensation and benefits as outlined in these Terms and Conditions, reflecting our commitment to a mutually beneficial and productive relationship.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>1. SIGNING UP TO THE AFFILIATE PROGRAMME</SubTitle>
        <Text>
        To become an affiliate of the Affiliates Programme, you should register as an affiliate by completing the online application form (the "IO"). The information you provide in your IO must be genuine and complete for it to be taken into consideration for approval. Vital Edge may decide, at its sole discretion, not to accept your request after considering it incomplete, irrelevant, or not genuine in any aspect.        
        </Text>

        <Text>
        Should your application be approved, you will be informed by an email of confirmation sent to the email address you provided in your IO with a username and a password. The username and the password are yours, and you shouldn’t provide them by any means to any third party. The username and the password are unique to You and ensure that You will access Your Affiliate dashboard easily, allowing You to select promotional materials from our extensive gallery, customize your advertising strategies, and utilize our advanced reporting tools to track your commission growth.        
        </Text>

        <Text>
        Vital Edge may refuse to approve your application to join the affiliate programme at any time for any reason at its sole discretion.
        </Text>

        <Text>
        Vital Edge does not allow employees or persons related to them to participate in the Vital Edge Affiliate programme.        
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>2.	VITAL EDGE'S RIGHTS AND OBLIGATIONS</SubTitle>
        <Text>
        Always subject to Clause 3.1:        
        </Text>

        <Text>
        •	We will register customers and track their transactions. Once registered, the customer becomes our customer and must accept all our rules, policies, and operating procedures.      
        </Text>

        <Text>
        •	We will pay you Commission in accordance with Clause 4 below.        
        </Text>

        <Text>
        •	We will use our reasonable endeavors to provide you with password-protected access to a report in the Affiliate Area that shows the Net Revenue of customers across our products and services.        
        </Text>

        <Text>
        •	We will use our reasonable endeavors to tag the identity of all Visitors; track the engagement and usage patterns of Customers record all bets and stakes made by Customers; record the history of payments of Commission made to you.        
        </Text>

        <Text>
        IF YOU DECIDE NOT TO ACCEPT ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, PLEASE DO NOT DOWNLOAD BANNER CODE, TEXT LINK CODE, PROMOTIONAL COPY OR ANY INFORMATION PERTAINING TO VITAL EDGE.        
        </Text>

        <Text>
        We reserve the right to terminate this Agreement if we determine that your site is unsuitable. Unsuitable sites may include those that: promote sexually explicit materials, are targeted towards children or minors, promote violence, promote discrimination based on race, sex, religion, nationality, disability, sexual orientation, promote illegal activities, or violate intellectual property rights ("IP rights").        
        </Text>

        <Text>
        As a policy, Vital Edge will not accept an online application for registration as an affiliate if you advertise with Internet Protocol (IP) addresses located in or originating from within the geographical jurisdiction of the U.S., Iran, Iraq and Israel.        
        </Text>

        <Text>
        This Agreement sets all the terms and conditions of membership to the Vital Edge Affiliate Programme. "You", the "affiliate" refers to you, the individual, group, or corporate entity registering with us as an affiliate under this Agreement, and will be deemed to include any employees, officers, directors, shareholders, owners, controlling parties, and affiliated individuals and entities (and "your" has a corresponding meaning).        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>3.	AFFILIATE RIGHTS AND OBLIGATIONS</SubTitle>
        <Text>
        1.	As an affiliate, you are allowed to open and run only one affiliate account; odd accounts will be closed at Vital Edge's sole discretion.        </Text>

        <Text>
        2.	As an Affiliate, you are responsible for promoting Vital Edge by implementing the advertising, banners, and tracking URLs on your websites, emails, or other communications. You will agree to register and maintain correct and truthful contact information with Vital Edge.        </Text>

        <Text>
        3.	You will present only content and topics on your site that are pre-approved in writing by Vital Edge. Content that is deemed unsuitable in Vital Edge's sole discretion will result in the affiliate agreement being terminated immediately. Content is deemed unsuitable in cases where it is aimed at individuals under the age of 18 years.        </Text>

        <Text>
        4.	You will be solely responsible for the development, operation, and maintenance of your site and for all materials that appear on your site and at your own cost. For example, you will be solely responsible for ensuring that materials posted on your site are not libelous or otherwise illegal or infringe the IP rights of third parties. Since we do not have control over your own site, we disclaim all liability for these matters. Furthermore, you will indemnify and hold us harmless from any and all claims, losses, damages, and expenses (including, without limitation, attorney's fees) relating to the development, operation, maintenance, and contents of your site. This provision on indemnification is without prejudice to our separate action or claim against you under applicable laws.        </Text>

      </TextBox>

      <TextBox className="term">
        <SubTitle>4.	AFFILIATION GUIDELINES</SubTitle>
        <Text>
        1.	Only approved and properly tagged creative materials, supplied by us from time to time, may be used to promote Vital Edge. Advertorials and personal endorsements are allowed but all materials not designed by Vital Edge must be approved in writing; such approval shall not be unreasonably withheld.      
        </Text>

        <Text>
        2.	You may not modify or use for any other purpose any IP rights, banners, or other creative material supplied by us without the written prior consent of Vital Edge. All copyright or IP rights notices on any material supplied or approved by Vital Edge must remain and not be modified or eliminated.         
        </Text>

        <Text>
        3.	By agreeing to participate in the Vital Edge Affiliate Programme, you are agreeing to download banners, text, or promotional material and place it on your site, utilize it within email, direct marketing using your affiliate URL, or print. These methods are the only approved methods by which you may advertise Vital Edge's products and services.        
        </Text>

        <Text>
        4.	Banners and links may not be placed within unsolicited email, unauthorized newsgroup postings, or chat rooms or through the use of "bots". Traffic generated illegally will not be counted and may result in the termination of your affiliate account with us.        
        </Text>

        <Text>
        5.	We will terminate this Agreement immediately if there is any form of spamming on your behalf or if you discredit Vital Edge through false advertising, written or uttered words.        
        </Text>

        <Text>
        6.	Without our prior written approval, you will only use our approved banners and links and will not alter their appearance nor refer to us in any promotional materials. The appearance and syntax of the hypertext transfer links are designed and designated by us and constitute the only authorized and permitted representation of Vital Edge.        
        </Text>

        <Text>
        7.	You will not knowingly benefit from known or suspected traffic not generated in good faith whether or not it actually causes Vital Edge damage. Should fraudulent activity arise through a person directed to a site via your link, we retain the right to retract the commissions paid to you at any time. Our decision in this regard will be final and no correspondence will be entered into. We reserve the right to retain all amounts due to you under this Agreement if we have reasonable cause to believe that such traffic has been caused with your knowledge. Even if you have not knowingly generated such traffic, we reserve the right to withhold affiliate commissions with respect to such.        
        </Text>

        <Text>
        8.	Affiliates are prohibited from playing any of the sports books/casino/games promoted by Vital Edge through their own affiliate link or from the affiliate link of an associate who shares all or part of the commission with the affiliate or a third party. This includes any individuals or playing groups from which an Affiliate receives compensation or remuneration of any kind. In the event of a violation of this section, Vital Edge and the respective website that has been played with have the right to void all play and eliminate any earned commissions applicable from such play and eliminate the affiliate from our Affiliate Programme, terminating this Agreement and void any future play at Vital Edge.        
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>5.	PAYMENT TERMS</SubTitle>
        <Text>
        1.	Vital Edge will pay out commissions, as defined by the Vital Edge Affiliate Programme commission structure, provided that you maintain in your account as an affiliate, at least five (5) different Active Engaged Users (as defined under clause 5(2) below) in any given calendar month. In cases where there are no new active wagering members within a 6 (six) months period, your account will be terminated at our sole discretion, and all commissions generated during those months will be revoked.        
        </Text>

        <Text>
        2.	Active Engaged Users refers to referrals, which have performed a minimum deposit of 10 EUR or equivalent (for e-wallet deposits), 25 EUR or equivalent (for credit card deposits) and 100 EUR (for bank wires).        
        </Text>

        <Text>
        3.	Commissions will be paid monthly and will be calculated only at the end of the month and according to the total revenues generated during the given month.        
        </Text>

        <Text>
        4.	Commissionable Earnings are calculated as Vital Edge's profit, less charge-back, complimentary money, free money offers, and other incentives offered to the customer, derived from at least five (5) active engaged users in a calendar month.         
        </Text>

        <Text>
        a.	Charge-back: A credit card holder discovers irregular transactions made on his/her Credit/Debit Card, which was not authorized by him/her. The credit card holder then requests his/her bank to reverse these charges. Charge backs relate to fraudulent use by a third party of the credit card holder's card or card number.        
        </Text>

        <Text>
        b.	Should the customer process a charge back, the disputed or charged-back revenue generated by yourself will be forfeited and therefore deducted from the total balance due to you for the current month.         
        </Text>

        <Text>
        c.	Should this deduction of the accumulated revenue exceed your current amount due, your balance will then revert to a negative balance, and you will have to earn revenue to cover the charge-back before you can start earning revenue again.        
        </Text>

        <Text>
        d.	Unlike with a customer making a big winning, which only lasts one month, a charge back will stay due until the revenue generated by your other referrals has covered the amount due.d.	Unlike with a customer making a big winning, which only lasts one month, a charge back will stay due until the revenue generated by your other referrals has covered the amount due.        
        </Text>

        <Text>
        e.	It is hereby clarified that Vital Edge cannot pay out fraudulent revenue.         
        </Text>

        <Text>
        5.	Should you fail to be entitled to the Commissionable Earnings for failure to maintain at least five (5 Active Engaged Users in a given month, you may be given (at Vital Edge sole discretion) a grace period of two (2) months within which to comply. Upon compliance, your commission for the subject period not exceeding three (3) consecutive months will be consolidated to constitute your commissionable earnings for the same period. (at Vital Edge’s sole discretion)
        </Text>

        <Text>
        6.	Commissionable earnings will be subject to commissions as calculated using the Vital Edge Affiliate Programme commission structure in force from time to time.        
        </Text>

        <Text>
        7.	There will be no negative loss carryovers from month to month.         
        </Text>
      </TextBox>

      
      <TextBox className="term">
        <SubTitle>6.	CONFIDENTIALITY</SubTitle>
        <Text>
        1.	Vital Edge assumes ownership of the customer relationship at the point of first engagement. You, as an affiliate, act as a referring agent for Vital Edge. We reserve the right to refuse service to customers (or to terminate service agreements) if necessary to comply with any requirements we may periodically establish.        </Text>

        <Text>
        2.	By engaging with Vital Edge's services or products through your referral, that person or entity will become our customers and, accordingly, all client rules, policies, and operating procedures will apply to them.        </Text>

        <Text>
        3.	During the term of this Agreement, you may be entrusted with confidential information relating to the business, operations, or underlying technology of our affiliate programme (including, for example, referral fees earned by you under the programme). You agree not to disclose or use the confidential information to third persons or outside parties unless you have our prior written consent and that you will use the confidential information only for purposes necessary to further the purposes of this Agreement. Your obligations with respect to confidential information will survive the termination of this Agreement.        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>7.	LIMITED LICENSE</SubTitle>
        <Text>
        1.	Vital Edge hereby grants to you a personal, non-exclusive, non-transferable limited license, during the term of this Agreement, to use Vital Edge's trademarks and intellectual property solely in connection with the display of the banners on your site.        </Text>

        <Text>
        2.	By this Agreement, we grant you the non-exclusive right to direct customers to our software and services, in accordance with the terms and conditions of this Agreement. This Agreement does not grant you an exclusive right or privilege to assist us in the provision of services arising from your referrals, and we reserve the right to contact with and obtain the assistance from other parties at any time to perform services of the same or similar nature as yours. You will have no claims to referral fees or other compensation on business secured by or through persons or entities other than you. </Text>
        <Text>
        3.	This license cannot be sub-licensed, assigned or otherwise transferred by you in any circumstances. Your right to use the trademarks is limited to and arises only out of this license to use the banners. You agree and acknowledge that all the rights, title and interest in and to the intellectual property are solely owned by and irrevocably assigned to Vital Edge. You will not assert the invalidity, unenforceability, or contest the ownership of the trademarks in any action or proceeding of whatever kind or nature, and shall not take any action that may prejudice our or our licensor's rights in the trademarks, render the same generic, or otherwise weaken their validity or diminish their associated goodwill.   </Text>   
        
        <Text>
        4.	We reserve the right to revoke this license at any time and it will be deemed revoked at the termination of this Agreement, for whatsoever reason.   
        </Text>     
      </TextBox>

      <TextBox className="term">
        <SubTitle>8.	 LEGAL RESPONSIBILITIES</SubTitle>
        <Text>
        1.	Ownership and content of our products and services remain our respective properties and shall not be deemed to have been transferred to the affiliate through any act or omission in respect of the affiliation Agreement.</Text>

        <Text>
        2.	Ownership, content, and liability for affiliate sites are the sole responsibility of the affiliate. You will be solely responsible for the development, operation, and maintenance of your site and for all materials that appear on your site and at your own cost.
       </Text>

        <Text>
        3.	You will indemnify and hold us unaccountable from all claims, damages, and expenses (including, without limitation, attorneys' fees) relating to the development, operation, maintenance, and contents of your site. This provision on indemnification is without prejudice to our separate action or claim against you under applicable laws.   </Text>   
        
        <Text>
        4.	It is the affiliates' responsibility to follow the correct linking and tagging procedure to ensure new customer tracking and payment.
        </Text> 

          <Text>
          5.	Presentation of our banners & content on the affiliate's site is the responsibility of the affiliate. The affiliate must ensure that our content is presented in accordance with our outlines.
          </Text>     

          <Text>
          6.	The affiliate must ensure that any material posted on their site is legal and does not infringe copyright or violate any unlawful rights.
        </Text> 

        <Text>
        7.	We may modify any of the terms and conditions contained in this Agreement, at any time and it is our sole discretion, by posting a change notice or a new agreement on our site. Modifications may include, for example, changes in the scope of available referral fees, fee schedules, payment procedures, and referral program rules. IF ANY MODIFICATIONS ARE UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO TERMINATE THIS AGREEMENT. YOUR CONTINUED PARTICIPATION IN THE PROGRAM FOLLOWING OUR POSTING OF A CHANGE NOTICE OR NEW AGREEMENT ON OUR SITE WILL CONSTITUTE BINDING ACCEPTANCE OF THE CHANGE.
        </Text> 
      </TextBox>


      <TextBox className="term">
        <SubTitle>9.	TERM AND TERMINATION</SubTitle>
        <Text>
        1.	The term of this Agreement will begin when you download the linking code and link it to our software and will be continuous unless and until either party notifies the other in writing that it wishes to terminate the Agreement, in which case this agreement may be terminated immediately. For purposes of notification of termination, delivery via e-mail is considered a written and immediate form of notification.</Text>

        <Text>
        2.	In the event of termination of this Agreement:       
        </Text>

        <Text>
        a.	You must remove Vital Edge's promotional materials from your site and disable any links from your site to Vital Edge.   
        </Text>   
        
        <Text>
        b.	All rights and licenses given to you under this Agreement are immediately terminated.
        </Text> 

          <Text>
          c.	You will be entitled only to those unpaid referral fees if any earned by you on or prior to the date of termination, save where termination is as a result of your breach of terms of this Agreement, as detailed in subsection (d) below. We may withhold your final payment for a reasonable amount of time to ensure that the correct amount is paid. 5.	Presentation of our banners & content on the affiliate's site is the responsibility of the affiliate. The affiliate must ensure that our content is presented in accordance with our outlines.
          </Text>     

          <Text>
          d.	If you have failed to fulfill your obligations and responsibilities, Vital Edge reserves the right to not pay you the referral fees otherwise owing to you on termination.
        </Text> 

        <Text>
        e.	We may withhold your final payment for a reasonable amount of time to ensure that the correct amount is paid.
        </Text> 

        <Text>
        f.	 If we continue to permit activity (generation of revenue) from customers after termination, this will not constitute a continuation or renewal of this Agreement or a waiver of termination.
        </Text>

        <Text>
        g.	Upon expiration or earlier termination of this Agreement, You will and are obligated to return to us any confidential information and all copies of it in your possession, custody and control and will cease all uses of any trade names, trademarks, service marks, logos and other designations of Vital Edge.
        </Text>

        <Text>
        h.	You and Vital Edge will be released from all obligations and liabilities to each other occurring or arising after the date of such termination, except with respect to those obligations that by their nature are designed to survive termination, as set out in this Agreement. Termination will not relieve you from any liability arising from any breach of this Agreement, which occurred prior to termination.         </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>10.	 RELATIONSHIP OF PARTIES</SubTitle>
        <Text>
        You and Vital Edge are independent contractors, and nothing in this agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties. You will have no authority to make or accept any offers or representations on our behalf. You will not make any statement, whether on your site or otherwise, to contradict anything in this paragraph or be reasonably deemed to contradict this paragraph.
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>11.	INDEMNITY</SubTitle>
        <Text>
        You hereby agree to indemnify and hold harmless Vital Edge and its affiliates, including its directors, officers, employees, agents, shareholders, partners, members and other owners, against any and all claims, actions, demands, liabilities, losses, damages, judgments, settlements, expenses, penalties (including reasonable attorneys' fees), and costs (any or all of the foregoing hereinafter referred to as "Losses") insofar as such Losses (or actions in respect thereof) arise out of or are based on: (i) any claim that our use of the affiliate trademarks infringes on any trademark, trade name, service mark, copyright, license, intellectual property, or other proprietary right of any third party; (ii) any misrepresentation of a representation or warranty or breach of a covenant and agreement made by you herein;(iii) the development, operation, maintenance and content of your site and products and services offered from your site; (iv) any claim related to your site, including, without limitation, content therein not attributable to us.        
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>12.	 LIMITATION OF LIABILITY</SubTitle>
        <Text>
        Vital Edge is not liable for any indirect, special, or consequential damages (or any loss of revenue, profits, or data) arising in connection with this agreement or the affiliate programme, even if we have been advised of the possibility of such damages. Furthermore, Vital Edge's aggregate liability arising with respect to this Agreement and the programme will not exceed the total commissions paid or payable to you under this Agreement. Nothing in this Agreement is to be construed to provide any rights, remedies or benefits to any person or entity not a party to this Agreement. Our obligations under this Agreement do not constitute personal obligations of our directors, officers or shareholders. Any liability arising under this Agreement shall be satisfied solely from the referral fee generated and is limited to direct damages only.        
        </Text>
      </TextBox>

      <TextBox className="term">
        <SubTitle>13.	MISCELLANEOUS</SubTitle>
        <Text>
        1.	Neither party shall assign its rights or obligations under this Agreement. This Agreement shall be binding upon the respective successors and assigns of the parties hereto. No delay or omission by either party in exercising any right under this Agreement shall operate as a waiver of that or any other right. If any provision of this Agreement shall be held invalid or unenforceable for any reason, such invalidity or unenforceability shall attach only to such provision and shall not affect or invalidate any other provision of this Agreement.         
        </Text>

        <Text>
        2.	We make no express or implied warranties or representations with respect to the Affiliate Programme or referral fee payment arrangements (including, without limitation, their functionality, warranties of fitness, merchantability, legality, non-infringement, or any implied warranties arising out of a course of performance, dealing, or trade usage). In addition, we make no representation that the operation of our software, services, or platforms will be uninterrupted or error-free and will not be liable for the consequences of any interruptions or errors       
        </Text>

        <Text>
        3.	YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS TERMS AND CONDITIONS. YOU UNDERSTAND THAT WE MAY AT ANY TIME (DIRECTLY OR INDIRECTLY) SOLICIT CUSTOMER REFERRALS ON TERMS THAT MAY DIFFER FROM THOSE CONTAINED IN THIS AGREEMENT OR OPERATE PLATFORMS AND SERVICES THAT ARE SIMILAR TO OR COMPETE WITH YOUR SERVICES. YOU HAVE INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THIS AFFILIATE PROGRAMME AND ARE NOT RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS SET FORTH IN THIS AGREEMENT.        
        </Text>

        <Text>
        IN WITNESS WHEREOF, by having read the terms and conditions, and acknowledging such in the affiliate sign-up form you agree to all the terms and conditions contained herein. Vital Edge reserves the right to withhold payment from any affiliate that violates any of the terms and conditions contained in this Agreement.
        </Text>
      </TextBox>
    </TermsContainer>
  );
};

export default Terms;
