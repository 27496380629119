import React, { useState } from 'react';
import styled from 'styled-components';

const LoginContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.975); /* Semi-transparent black background */
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')}; /* Show or hide based on the isVisible state */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
`;

const LoginForm = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 40px solid transparent;
  border-image: url('/img/border.png') 30 stretch;
  width: 80%; /* Adjust width as needed */
  max-width: 600px; /* Limit maximum width */
  height: auto;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 2em;
`;

const Logo = styled.img`
  width: 70%; /* Adjust width as needed */
  max-width: 200px; /* Limit maximum width */
  margin-bottom: 3em;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70px;
  padding: 5px 0;
  margin-bottom: 30px;
  border: 2px solid #b98844;
  border-radius: 5px;
  background: black;
  color: white;
  font-size: 15px;
  position: relative;
  outline: none;

  @media (max-width: 630px) {
    width: 90%;
  }

  @media (max-width: 555px) {
    width: 100%;
  }


`;

const CheckboxImage = styled.img`
  width: auto; 
  position: absolute;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  max-height: 70%;

  @media (max-width: 490px) {
    position: static; /* Change position to static to place it on the left */
    text-align: left; /* Align the text to the left */
  }
`;

const CheckboxInput = styled.input`
  width: 35px; /* Adjust width as needed */
  height: 30px;
  position: relative; /* Required for positioning the ::before pseudo-element */
  appearance: none; 
  background-color: transparent; /* Make the background transparent */
  border: none; /* Remove any border */
  border-radius: 5px;
  outline: 2px solid #b98844; /* Add black outline */
  margin-left: auto; /* Push the checkbox to the right */
  order: 1; /* Place the checkbox after the image and text */
  margin-right: 20px;
  cursor: pointer;

  &::before {
    content: ''; /* Required content for the ::before pseudo-element */
    position: absolute; /* Position the pseudo-element relative to the checkbox */
    top: 0; /* Align at the top */
    left: 0; /* Align at the left */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: black;
    opacity: 0.5; /* Adjust opacity as needed */
    z-index: -1; /* Ensure the pseudo-element is behind the checkbox */
  }

  &:checked::before {
    background-color: transparent; /* Make the background transparent when checked */
  }

  &:checked::after {
    content: '✔'; /* Content for the ::after pseudo-element */
    position: absolute; /* Position the pseudo-element relative to the checkbox */
    top: 40%; /* Align at the center vertically */
    right: 0; /* Align at the right */
    transform: translate(50%, -50%); /* Center the mark */
    font-size: 20px; /* Adjust font size as needed */
    color: #b98844; /* Yellow color for the mark */
    z-index: 1; /* Ensure the pseudo-element is above the checkbox */
    margin-right: 15px;
  }
`;

const Button = styled.button`
  width: 80%; /* Adjust width as needed */
  max-width: 200px; /* Limit maximum width */
  height: 40px;
  font-size: 20px;
  background-color: #b98844;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover{
    background-color: black;
    color: #b98844;
    border:2px solid #b98844;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 5px;
  right: 10px;
  width: 20px;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  color: white;
  font-size: 1.7em; 
  font-weight: bold;
  width: auto; 
  position: absolute;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  max-height: 70%;

  @media (max-width: 490px) {
    position: static; /* Change position to static to place it on the left */
    text-align: left; /* Align the text to the left */
  }

  @media (max-width: 415px) {
    position: static; /* Change position to static to place it on the left */
    text-align: left; /* Align the text to the left */
    white-space: pre-line;
    align-items: left;
  }
`;

const Register = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(true); // Initially set to true to show the login window
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle the visibility state
  };

  const handleCheckboxChange = (id) => {
    if (selectedCheckbox === id) {
      // If the clicked checkbox is already selected, deselect it
      setSelectedCheckbox(null);
    } else {
      // Otherwise, select the clicked checkbox
      setSelectedCheckbox(id);
    }
  };

  const handleRegistration = () => {
    
    if (selectedCheckbox === "betsfunk") {
      window.open("#", "_blank");
    } else if (selectedCheckbox === "zeuswin") {
      window.open("https://ro-affiliate.clubaffiliates.com/registration", "_blank");
    } else {
      window.open("#", "_blank");
    }
  };
  return (
    <LoginContainer id="register" isVisible={isVisible} onClick={() => { onClose(); toggleVisibility(); }}>
      <LoginForm>
        <CloseButton src="/img/xmenu.png" alt="Close" onClick={() => { onClose(); toggleVisibility(); }} />
        <Logo src="/img/Logo.png" alt="Logo" />

        <CheckboxContainer style={{ padding:'10px 0'}}  onClick={(event) => {event.stopPropagation() }}>
          <CheckboxInput type="checkbox" id="zeuswin" checked={selectedCheckbox === "zeuswin"}
            onChange={() => handleCheckboxChange("zeuswin")} />
            <CheckboxImage src="/img/zeuswinLogo.png" alt="zeuswin logo" />
            <CheckboxLabel htmlFor="zeuswin"/>
        </CheckboxContainer>
        
        <CheckboxContainer style={{ padding:'10px 0'}}  onClick={(event) => {event.stopPropagation() }}>
          <CheckboxInput type="checkbox" id="placebet" checked={selectedCheckbox === "placebet"}
            onChange={() => handleCheckboxChange("placebet")} />
            <CheckboxImage src="/img/placebet.svg" alt="placebet logo" />
            <CheckboxLabel htmlFor="placebet"/>
        </CheckboxContainer>

        <CheckboxContainer style={{ padding:'10px 0'}}  onClick={(event) => {event.stopPropagation() }}>
          <CheckboxInput type="checkbox" id="betsfunk" checked={selectedCheckbox === "betsfunk"}
            onChange={() => handleCheckboxChange("betsfunk")} />
            <CheckboxImage src="/img/betfunklogo.png" alt="betfunk logo" />
            <CheckboxLabel htmlFor="betsfunk"/>
        </CheckboxContainer>

  
      </LoginForm>
      <Button onClick={handleRegistration}>REGISTER</Button>
    </LoginContainer>
  );
};

export default Register;
