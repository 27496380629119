import React, { useRef, useState } from 'react';
import './App.css';
import Nav from './components/Navbar.jsx'; 
import Home from './components/Home.jsx'; 
import Why from './components/Why'; 
import Brands from './components/Brands'; 
import Testimonials from './components/Testimonials'; 
import Faq from './components/Faq'; 
import Contact from './components/Contact'; 
import Footer from './components/Footer'; 
import Testimonial from './components/Testi'; 
import Login from './components/Login'; 
import Terms from './components/Terms';
import ScrollTo from './components/ScrollTo'; 
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
`;

function App() {
  const sectionRefs = {
    'home': useRef(null),
    'contact': useRef(null),
    'about': useRef(null),
    'brands': useRef(null),
    'faq': useRef(null),
    'testimonials': useRef(null),
  };

  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleLoginClose = () => {
    setShowLogin(false);
  };

  return (
    <Router>
      <Container>
        <Nav sectionRefs={sectionRefs}/>
        {showLogin && <Login onClose={handleLoginClose} />}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home id="home" ref={sectionRefs.home} sectionRefs={sectionRefs} />
                <Why id="about" ref={sectionRefs.about} sectionRefs={sectionRefs} />
                <Brands id="brands" ref={sectionRefs.brands} sectionRefs={sectionRefs} />
                <Testimonials id="testimonials" ref={sectionRefs.testimonials} sectionRefs={sectionRefs} />
                <Faq id="faq" ref={sectionRefs.faq} sectionRefs={sectionRefs} />
                <Contact id="contact" ref={sectionRefs.contact} sectionRefs={sectionRefs} />
                <Footer/>
              </>
            }
          />

          <Route
            path="/testimonial"
            element={
              <>
                <Testimonial/>
                <Footer/>
              </>
            }
          />

          <Route  
            path="/terms"
            element={
              <>
                <Terms/>
                <Footer/>
              </>
            }
          />
            

        </Routes>
        <ScrollTo/>
      </Container>
    </Router>
  );
}

export default App;
