import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 5%;
  right: 2%;
  background-color: transparent;
  border: 20px solid transparent;
  border-image: url('/img/border.png') 30 stretch;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  z-index:1000;

  @media (max-width: 500px) {
    display: none;
  }
`;

const ArrowIcon = styled.img`
  width: 25px;
  height: 15px;
`;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ScrollToTopButton onClick={scrollToTop} >
      <ArrowIcon src="/img/Aup.png" alt="Scroll to Top" />
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
